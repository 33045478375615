$theme-colors: (
  'primary': #c89d5c,
  'secondary': #535353,
  'success': #73de4e,
  'dark': #727272,
  'info': #7a7a7a,
);
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4.5,
);
