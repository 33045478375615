* {
  font-family: '黑體-繁', '微軟正黑體';
}
html {
  scroll-behavior: smooth;
}
#navbar_mobile {
  transition: top 0.2s ease;
}
// mobile-start
.section {
  height: 100vh;
}
.first-banner-mobile {
  padding-top: 5rem;
  .btn-banner {
    position: absolute;
    right: -10px;
    transform: translateY(-40%);
    border-radius: 57px;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 0.75rem;
    letter-spacing: 0.3rem;
  }
  .btn-contact {
    box-shadow: 0px 1px 5px 0px #00000040;
    width: 45%;
    letter-spacing: 0.1rem;
    &.btn-contact-line {
      font-size: 0.8rem;
    }
  }
  .carousel-indicators {
    transform: translateY(320%);
    button {
      background-color: #7a7a7a;
      &.active {
        opacity: 1;
        background-color: #c89d5c;
      }
    }
  }
  .carousel-inner {
    border-radius: 32px;
    background-color: rgba(0, 0, 0, 0);
    overflow: hidden;
  }
  .carousel-item {
    height: 64vh;
  }
}
.info {
  position: relative;
  padding-top: 100px;
  overflow: hidden;
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-image: url(../assets/bg_info.webp);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 15%;
    transform-origin: 0 100%;
    transform: skewX(-75deg);
  }
  .info-title {
    letter-spacing: 0.1rem;
  }
  p {
    font-size: 0.75rem;
    font-weight: 700;
  }
  p:nth-of-type(1) {
    line-height: 3;
  }
}
.process {
  padding-top: 104px;
  .process-title {
    font-size: 0.75rem;
    width: 135px;
    border: #c89d5c solid 4px;
    border-radius: 18px;
    letter-spacing: 0.5rem;
  }
  ul {
    position: relative;
    padding: 0;
    &::before {
      content: ' ';
      z-index: -1;
      position: absolute;
      height: 95%;
      width: 6px;
      background-color: #c89d5c;
      left: 36%;
    }
  }
  li {
    margin-top: 43px;
    list-style: none;
    .title {
      font-size: 0.75rem;
      width: 50%;
    }
    .content {
      font-size: 0.75rem;
      width: 40%;
      text-align: center;
    }
  }
  p {
    font-size: 0.75rem;
    letter-spacing: 0.305rem;
  }
  .interest {
    position: relative;
    &::before {
      content: '( 利息僅2.5% )';
      position: absolute;
      display: block;
      width: 100%;
      color: #b2362e;
      font-weight: 900;
      bottom: -23px;
    }
  }
}
.serve {
  padding: 0 2rem;
  .title {
    font-size: 0.75rem;
    width: 135px;
    border: #c89d5c solid 4px;
    border-radius: 18px;
    letter-spacing: 0.5rem;
    margin-bottom: 32px;
  }
  .row {
    & > div {
      height: 100px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    div:nth-child(1) {
      background-image: url(../assets/bg_motor.webp);
    }
    div:nth-child(2) {
      background-image: url(../assets/bg_gold.webp);
    }
    div:nth-child(3) {
      background-image: url(../assets/bg_diamond.webp);
    }
    div:nth-child(4) {
      background-image: url(../assets/bg_house.webp);
    }
    .sub-title {
      display: block;
      width: 80px;
      border: 1px solid #c89d5c;
      font-size: 0.75rem;
    }
  }
}
.faq {
  padding: 0 2rem;
  .title {
    font-size: 0.75rem;
    width: 135px;
    border: #c89d5c solid 4px;
    border-radius: 18px;
    letter-spacing: 0.5rem;
  }
  .question {
    p:nth-child(1) {
      position: relative;
      font-size: 0.85rem;
      letter-spacing: 0.1rem;
      line-height: 18px;
      &::before {
        content: ' ';
        display: block;
        position: absolute;
        width: 75%;
        height: 2px;
        background-color: #ececec;
        bottom: -50%;
      }
    }
    p:nth-child(2) {
      background-color: #ececec;
      font-size: 0.75rem;
      letter-spacing: 0.1rem;
      line-height: 20px;
      border-radius: 12px;
    }
  }
}
.img-carousel {
  position: relative;
  .carousel {
    .carousel-item {
      min-height: 280px;
      max-height: 500px;
    }
  }
  .carousel-indicators {
    transform: translateY(250%);
  }
}
.enquiry-form {
  margin-top: 100px;
  margin-bottom: 52px;
  .head-title {
    width: 210px;
    border-top: solid 3px #c89d5c;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
  }
  .form-container {
    box-shadow: 2px 2px 5px 0px #0000001a;
    border-radius: 10px;
    a {
      font-size: 0.75rem;
      text-decoration: none;
    }
    input,
    select {
      box-shadow: 2px 2px 10px 0px #0000001a;
      background-color: #ebebeb;
      border-radius: 100px;
      border: none;
      padding: 3px 15px;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btn-submit {
    color: #ffffff;
    background-color: #c89d5c;
    border: none;
    border-radius: 101px;
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    width: 60px;
  }
  .tip {
    display: none;
    color: red;
    font-size: 0.75rem;
    font-weight: 700;
  }
}
.footer {
  .pic {
    width: 15px;
    height: 15px;
    background-color: #ebebeb;
  }
  .title {
    letter-spacing: 0.1rem;
    font-size: 5px;
    margin-bottom: 0;
    position: relative;
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      background-color: #c89d5c;
      bottom: -3px;
    }
  }
  .service-list {
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      list-style: none;
      font-size: 0.375rem;
      line-height: 2;
    }
    p {
      font-size: 0.75rem;
    }
  }
}
// mobile-end
// desktop-start
.first-banner-navbar {
  height: 11vh;
  h1 {
    border-bottom: 5px #c89d5c solid;
  }
  li {
    list-style: none;
    a {
      text-decoration: none;
    }
  }
}
@media screen and (min-width: 768px) {
  .navbar-desktop {
    transform: translateY(-100%);
    padding: 2rem 3rem;
    transition: all 0.2s ease;
    .title {
      position: relative;
      .contact-pad {
        position: absolute;
        right: 0;
        top: 0;
        .line-icon {
          color: white;
          background-color: #30c630;
          padding: 0 7px;
          border-radius: 10px;
        }
      }
    }
  }
  .carousel-container {
    height: 83vh;
    padding: 3% 10%;
    .carousel-indicators {
      flex-direction: column;
      bottom: auto;
      top: 5%;
      right: auto;
      left: -4%;
      margin: 0;
      button {
        border-radius: 100%;
        width: 12px;
        height: 12px;
        background-color: #7a7a7a;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        opacity: 1;
        &.active {
          background-color: #c89d5c;
        }
      }
    }
    .carousel-inner {
      border-radius: 32px;
      span {
        position: absolute;
        bottom: 10%;
        left: 5%;
        padding: 10px 50px;
        font-size: 2rem;
        letter-spacing: 0.5rem;
        border-radius: 57px;
        box-shadow: 0px 4px 4px 0px #00000040;
      }
      img {
        height: 100%;
      }
    }
  }
  .info {
    &::after {
      height: 20%;
      transform: skewX(-85deg);
    }
    h2 {
      font-size: 4rem;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        display: block;
        height: 7px;
        width: 200px;
        background-color: #c89d5c;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    p {
      font-size: 1.5rem;
      font-weight: 700;
    }
    p:nth-of-type(1) {
      line-height: 2;
    }
  }
  .process {
    .process-title {
      width: 28%;
      font-size: 2rem;
    }
    ul {
      padding: 0;
      li {
        width: 22%;
        img {
          width: 60%;
        }
        p {
          font-size: 2rem;
        }
        div {
          font-size: 1.375rem;
        }
      }
      li:nth-child(3) {
        position: relative;
        &::before {
          content: '( 利息僅 2.5% )';
          position: absolute;
          display: block;
          background-color: #ffffff;
          color: #c89d5c;
          font-weight: 900;
          font-size: 1.75rem;
          text-align: center;
          white-space: nowrap;
          bottom: -50px;
        }
      }
      li:last-child {
        position: relative;
        &::before {
          content: '絕不索取任何額外手續費';
          position: absolute;
          display: block;
          background-color: #b2362e;
          color: #ffffff;
          font-weight: 700;
          text-align: center;
          padding: 2px 5px;
          top: -7px;
          right: -10px;
          transform: rotate(17deg);
        }
      }
      &::before {
        content: '';
        left: -7%;
        width: 90%;
        height: 6px;
        top: 32%;
        transform: translateY(-100%);
      }
    }
  }
  .serve {
    padding: 0 5%;
    .title {
      width: 28%;
      font-size: 2rem;
      margin-bottom: 54px;
    }
    .row {
      margin-bottom: 78px;
      & > div {
        max-height: 236px;
        min-height: 200px;
        width: 24%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      div:nth-child(1) {
        background-image: url(../assets/bg_motor_desktop.webp);
      }
      div:nth-child(2) {
        background-image: url(../assets/bg_gold_desktop.webp);
      }
      div:nth-child(3) {
        background-image: url(../assets/bg_diamond_desktop.webp);
      }
      div:nth-child(4) {
        background-image: url(../assets/bg_house_desktop.webp);
      }
      .sub-title {
        display: block;
        width: 65%;
        border: 4px solid #c89d5c;
        font-size: 1.375rem;
      }
    }
    .personal {
      width: 60%;
      border-top: 5px solid #c89d5c;
      border-bottom: 5px solid #c89d5c;
      padding: 70px 0;
      h2 {
        border-right: 6px solid #c89d5c;
        border-left: 5px solid #c89d5c;
        letter-spacing: 0.5rem;
        display: inline-block;
        padding: 0 1rem;
      }
      p {
        font-size: 1.2rem;
        letter-spacing: 0.1rem;
        margin: 45px 0;
      }
      a {
        text-decoration: none;
        font-size: 1.6rem;
        letter-spacing: 0.1rem;
        padding: 0.5rem 1rem;
        border-radius: 16px;
      }
    }
  }
  .faq {
    margin-top: 100px;
    padding: 0 10%;
    .title {
      font-size: 2rem;
      width: 28%;
    }
    .question {
      p:nth-child(1) {
        position: relative;
        font-size: 2rem;
        line-height: 53px;
        &::before {
          bottom: -12%;
        }
      }
      p:nth-child(2) {
        background-color: #ececec;
        font-size: 1.5rem;
        line-height: 60px;
      }
    }
  }
  .img-carousel-desktop {
    background-color: #ececec;
    padding: 5% 2% 15% 2%;
    .carousel-indicators {
      bottom: -60px;
    }
    .carousel-inner {
      .carousel-item {
        height: 365px;
      }
    }
    .caption {
      padding: 0 2%;
      h3 {
        letter-spacing: 0.1rem;
        line-height: 57px;
        position: relative;
        font-size: 2.375rem;
        &::before {
          content: ' ';
          display: block;
          position: absolute;
          height: 5px;
          width: 45%;
          bottom: -10px;
          transform: translateX(-50%);
          left: 50%;
          background-color: #c89d5c;
        }
      }
      p {
        font-size: 1.5rem;
        letter-spacing: 0.1rem;
        line-height: 42px;
      }
    }
  }
  .enquiry-form {
    background-color: #ececec;
    margin: 0;
    padding: 0 10% !important;
    .divider {
      height: 7px;
      background-color: #d0d0d0;
      width: 55%;
      margin-bottom: 100px;
    }
    .form-container {
      background-color: #ffffff;
      padding: 4% 8%;
      margin-bottom: 150px;
      .sub-title {
        font-size: 1.25rem;
        margin-bottom: 40px;
        letter-spacing: 0.1rem;
      }
      .sub-contact {
        a {
          font-size: 1.25rem;
          margin-bottom: 40px;
          letter-spacing: 0.1rem;
        }
      }
      input,
      select {
        width: 90%;
        padding: 10px 15px;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      img {
        height: calc(100% - 1rem);
      }
    }
    .btn-submit {
      font-size: 1.25rem;
      width: 20%;
    }
  }
  .footer {
    .title {
      font-size: 2.375rem;
    }
    .service-list {
      li {
        font-size: 0.75rem;
        font-weight: 700;
      }
      p {
        font-weight: 700;
        font-size: 1.25rem;
        position: relative;
        &::before {
          content: ' ';
          position: absolute;
          display: block;
          width: 5rem;
          height: 1px;
          background-color: #c89d5c;
          bottom: 0;
        }
      }
    }
    .btn-title {
      font-size: 1.5rem;
      border: 1px solid #e5e5e5;
      border-radius: 10px;
      letter-spacing: 0.1rem;
      padding: 12px 40px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .navbar-desktop {
    .contact {
      position: absolute;
      right: 0;
      top: 0;
      a {
        width: 200px;
        border: 1px #e5e5e5 solid;
        border-radius: 10px;
        letter-spacing: 0.1rem;
        font-weight: 500;
        font-size: 18px;
        padding: 15px 15px;
        text-decoration: none;
      }
    }
  }
  .carousel-container {
    height: 89vh;
    .carousel-inner {
      span {
        padding: 10px 100px;
      }
      img {
        height: auto;
      }
    }
  }
  .process {
    .process-title {
      width: 22%;
    }
    ul {
      padding: 0 10%;
      &::before {
        content: '';
        left: -7%;
        width: 90%;
        height: 6px;
        top: 36%;
        transform: translateY(-100%);
      }
      li:last-child {
        position: relative;
        &::before {
          right: 0px;
        }
      }
    }
  }
  .serve {
    padding: 0 10%;
    .title {
      width: 22%;
      margin-bottom: 108px;
    }
    .row {
      margin-bottom: 119px;
    }
  }
  .faq {
    .title {
      width: 22%;
    }
  }
  .img-carousel-desktop {
    padding: 5% 10% 15% 10%;
    .carousel-inner {
      .carousel-item {
        height: 495px;
      }
    }
    .caption {
      padding: 0 10%;
    }
  }
  .enquiry-form {
    input,
    select {
      width: 75%;
      padding: 15px;
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
    .btn-submit {
      font-size: 1.5rem;
      width: 15%;
    }
  }
}
h-100 {
  height: 100%;
}
